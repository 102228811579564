import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @ts-ignore
import DashboardContainer from '../containers/DashboardContainer';
import ShopRoutes from './ShopRoutes';
import { ApplicationState } from '../reducers/initialState';
import StatisticRoutes from './StatisticRoutes';
import ProductRoutes from './ProductRoutes';
import ReleasesRoutes from '../releases/router/ReleasesRoutes';
import OrderRoutes from './OrderRoutes';
import ClosingRoutes from '../closings/router/ClosingRoutes';
import ClosingScheduleRoutes from '../closings/router/ClosingScheduleRoutes';
import PricingCategoryRoutes from './PricingCategoryRoutes';
import CheckoutDevicesRoutes from '../sco/router/CheckoutDevicesRoutes';
import NotFound from './NotFound';
import { Access } from '../access';
import ShopAccessRoutes from '../shopAccess/ShopAccessRoutes';
import UserManagementRoutes from '../userManagement/UserManagementRoutes';
import TaxRulesRoutes from './TaxRulesRoutes';
import ProjectConfigRoutes from '../projectConfig/ProjectConfigRoutes';
import AppUsersRoutes from '../appUser/AppUsersRoutes';
import AppAppearanceRoutes from '../appAppearance/AppAppearanceRoutes';
import ProductMenuRoutes from '../productsMenu/ProductMenuRoutes';

type NavigationState = Record<string, Access | undefined>;

function RoutesForAuthorizedUsers() {
  const navigation = useSelector<ApplicationState, NavigationState>(state => state.navigation);
  return (
    <Routes>
      <Route path="/project-config/*" element={<ProjectConfigRoutes access={navigation.projectConfig} />} />
      <Route path="/shops/*" element={<ShopRoutes data-testid="shop-routes" access={navigation.shops} />} />
      <Route path="/statistic/*" element={<StatisticRoutes access={navigation.statistics} />} />
      <Route path="/products/*" element={<ProductRoutes access={navigation.products} />} />
      <Route path="/pricing/categories/*" element={<PricingCategoryRoutes access={navigation.pricingCategories} />} />
      <Route path="/taxRules/*" element={<TaxRulesRoutes access={navigation.taxRules} />} />
      <Route path="/checkout-devices/*" element={<CheckoutDevicesRoutes access={navigation.checkoutDevices} />} />
      <Route path="/orders/*" element={<OrderRoutes access={navigation.orders} />} />
      <Route path="/closings/schedules/*" element={<ClosingScheduleRoutes access={navigation.closingsSchedules} />} />
      <Route path="/closings/*" element={<ClosingRoutes access={navigation.closings} />} />
      <Route path="/releases/*" element={<ReleasesRoutes access={navigation.releases} supervisorAccess={navigation.checkoutSupervisor} />} />
      <Route path="/shop-access/*" element={<ShopAccessRoutes access={navigation.shopAccess} />} />
      <Route path="/user-management/*" element={<UserManagementRoutes access={navigation.userManagement} />} />
      <Route path="/app-user-admin/*" element={<AppUsersRoutes access={navigation.appUserAdmin} />} />
      <Route path="/app-appearance/*" element={<AppAppearanceRoutes access={navigation.appCustomization} />} />
      <Route path="/product-menus/*" element={<ProductMenuRoutes access={navigation.productMenus} />} />
      <Route path="/" element={<DashboardContainer />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/*" element={<Navigate to="404" replace />} />
    </Routes>
  );
}

export default RoutesForAuthorizedUsers;
