import React from 'react';
import { useTranslation } from 'react-i18next';
import { FailureCause, PaymentState } from './orderTypes';
import AdvancedStateIndicator, { State } from '../components/AdvancedStateIndicator';
import TextWithSubText from '../components/TextWithSubText';
import { Transaction, TransactionState } from './types/TransactionsTypes';

const TRANSACTION_STATE_MAPPING: Record<TransactionState, State> = {
  [TransactionState.StateFinal]: State.Success,
  [TransactionState.StateTransferred]: State.Success,
  [TransactionState.StateSuccess]: State.Success,
  [TransactionState.StateUserAborted]: State.Warning,
  [TransactionState.StatePreconditionsNotMet]: State.Error,
  [TransactionState.StateSystemAborted]: State.Error,
  [TransactionState.StatePaymentFailed]: State.Error,
  [TransactionState.StateFailed]: State.Error,
};

export interface SimpleTransactionStateIndicatorProps {
  transaction: Transaction;
}

export default function SimpleTransactionStateIndicator({
  transaction,
}: SimpleTransactionStateIndicatorProps) {
  const { t } = useTranslation();
  const stateT = (inputState: State | TransactionState | PaymentState | FailureCause) => (
    inputState ? t(`orders.state.${inputState}`, { defaultValue: inputState }) : ''
  );

  return (
    <AdvancedStateIndicator
      state={TRANSACTION_STATE_MAPPING[transaction.state] || State.Warning}
      label={(
        <TextWithSubText
          text={stateT(transaction.state)}
          subText=""
        />
      )}
      variant="outlined"
      sx={{ height: 'auto' }}
    />
  );
}
