import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ProductData } from './useCategoriesApi';

export interface CategoriesProductDialogTableProps {
  values: ProductData[];
  onSelect: (product: ProductData) => void,
}

export default function CategoriesProductDialogTable({
  values,
  onSelect,
}: CategoriesProductDialogTableProps) {
  const { t } = useTranslation();

  const TableRowEntries = useMemo(() => ({ product }: { product: ProductData }) => (
    <TableRow>
      <TableCell
        align="left"
        onClick={() => onSelect(product)}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        {product.sku}
      </TableCell>
      <TableCell
        align="left"
        onClick={() => onSelect(product)}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        {product.name}
      </TableCell>
      <TableCell
        onClick={() => onSelect(product)}
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        <div style={{ width: 'fit-content', marginInline: 'auto' }}>
          {product.imageUrl ? (
            <img
              src={product.imageUrl}
              alt={product.name}
              style={{ maxHeight: '40px', maxWidth: '40px' }}
              loading="lazy"
            />
          ) : (
            <ImageNotSupportedIcon fontSize="large" color="disabled" />
          )}
        </div>
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="add product"
          onClick={() => onSelect(product)}
        >
          <AddIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  ), [onSelect]);

  return (
    <TableContainer sx={{ height: 'calc(50vh - 135px)' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>{t('product.sku')}</TableCell>
            <TableCell>{t('product.name')}</TableCell>
            <TableCell align="center">{t('product.image')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(values || []).map((product: ProductData) => (
            <TableRowEntries product={product} key={product.sku} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
