import React, { ReactNode } from 'react';
import Filter1 from '@mui/icons-material/Filter1';
import Filter2 from '@mui/icons-material/Filter2';
import Filter3 from '@mui/icons-material/Filter3';
import Filter4 from '@mui/icons-material/Filter4';
import Filter5 from '@mui/icons-material/Filter5';
import Filter6 from '@mui/icons-material/Filter6';
import Filter7 from '@mui/icons-material/Filter7';
import Filter8 from '@mui/icons-material/Filter8';
import Filter9 from '@mui/icons-material/Filter9';
import Filter9Plus from '@mui/icons-material/Filter9Plus';

const ICON_MAP: ReactNode[] = [
  <Filter1 fontSize="small" />,
  <Filter2 fontSize="small" />,
  <Filter3 fontSize="small" />,
  <Filter4 fontSize="small" />,
  <Filter5 fontSize="small" />,
  <Filter6 fontSize="small" />,
  <Filter7 fontSize="small" />,
  <Filter8 fontSize="small" />,
  <Filter9 fontSize="small" />,
  <Filter9Plus fontSize="small" />,
];

interface FilterIconSelectorProps {
  number: number;
}

export default function FilterIconSelector({
  number,
}: FilterIconSelectorProps) {
  const sanitizedNumber = Math.min(Math.max(number - 1, 0), 9);
  return <>{ICON_MAP[sanitizedNumber]}</>;
}
