import React from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import PaperTableRow from './PaperTableRow';
// @ts-ignore
import PaperTable from './PaperTable';
import DownloadAsLink from './DownloadAsLink';
// @ts-ignore
import LocaleDate from './LocaleDate';
import { Transaction } from '../orders/types/TransactionsTypes';
import SimpleTransactionStateIndicator from '../orders/SimpleTransactionStateIndicator';

interface OrderViewGeneralProps {
  transaction: Transaction,
  receipt: any,
}

function OrderViewGeneral({
  transaction,
  receipt,
}: OrderViewGeneralProps) {
  const { t } = useTranslation();

  if (!transaction) return null;

  const rows: any = [
    { key: 'id', value: transaction.id },
    {
      key: 'state.label',
      value: (
        <SimpleTransactionStateIndicator transaction={transaction} />
      ),
    },
  ];

  if (transaction.externalCheckoutID) {
    rows.push({
      key: 'externalCheckoutID',
      value: transaction.externalCheckoutID,
    });
  }

  if (transaction.externalCheckoutReference) {
    rows.push({
      key: 'externalCheckoutReference',
      value: transaction.externalCheckoutReference,
    });
  }

  if (transaction.finalizedAt) {
    rows.push({
      key: 'finalizedAt',
      value: <LocaleDate date={transaction.finalizedAt} />,
    });
  }

  rows.push({
    key: 'loyaltyCard',
    value: transaction?.customer?.loyaltyCard || '-/-',
  });

  if (receipt) {
    rows.push({
      key: 'receipt',
      value: (
        <DownloadAsLink
          blob={receipt}
          name={`receipt-${transaction.id}`}
          type="PDF"
        />
      ),
    });
  }

  return (
    <PaperTable headline={t('orders.general')}>
      {rows.map((row: any) => {
        const transactionsRowKey = `orders.${row.key ?? 'none'}`;
        return (
          <PaperTableRow
            key={row.key}
            label={t(transactionsRowKey)}
            value={row.value || '–'}
          />
        );
      })}
    </PaperTable>
  );
}

export default OrderViewGeneral;
