import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import useCategoriesApi, { useCategoryProductApi } from './useCategoriesApi';
import ResourceFormView from '../scaffold/ResourceFormView';
import { projectUsesOldCategoriesEditor } from '../utils/features';
import SingleItemsForm from './SingleItemsForm';
import DocumentTitle from '../components/DocumentTitle';

export default function UpdateSingleItemsView() {
  const api = useCategoriesApi();
  const productsApi = useCategoryProductApi();
  const { projectId } = useParams();

  const handleProductLookup = useCallback(async (sku: string) => {
    const products = await productsApi.getProduct({ sku });
    return products;
  }, [productsApi]);

  if (projectId && projectUsesOldCategoriesEditor(projectId)) {
    // TODO hide for tegut and srs projects
  }

  return (
    <>
      <DocumentTitle translationID="productMenus.headlines.editSingleItems" />
      <ResourceFormView
        actionName="updateSingleItems"
        Form={SingleItemsForm}
        FormProps={{
          onProductLookup: handleProductLookup,
        }}
        fetch={() => api.get({})}
        submit={data => api.update({ data })}
      />
    </>
  );
}
