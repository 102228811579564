import React from 'react';
import Translate from './i18n/Translate';
// @ts-ignore
import PaperTableRow from './PaperTableRow';
// @ts-ignore
import PaperTable from './PaperTable';
import { Transaction } from '../orders/types/TransactionsTypes';

function OrderViewDevice({ transaction }: { transaction: Transaction }) {
  if (!transaction) return null;

  if (transaction.checkoutDevice?.id && transaction.appUser?.id) {
    return (
      <PaperTable headline={<Translate id="orders.device" />}>
        <PaperTableRow label={<Translate id="orders.type" />} value={<React.Fragment><Translate id="orders.typeApp" /> + <Translate id="orders.typeSco" /></React.Fragment>} />
        <PaperTableRow label={<Translate id="orders.scoExternal" />} value={!!transaction.checkoutDevice && transaction.checkoutDevice.externalID} />
        <PaperTableRow label={<Translate id="orders.scoID" />} value={transaction.checkoutDevice.id} />
        <PaperTableRow label={<Translate id="orders.sco" />} value={!!transaction.checkoutDevice && transaction.checkoutDevice.name} />
        <PaperTableRow label={<Translate id="orders.appUserID" />} value={transaction.appUser.id} />
        <PaperTableRow label={<Translate id="orders.clientID" />} value={transaction.appUser.clientID} />
        <PaperTableRow label={<Translate id="orders.sessionID" />} value={transaction.session} />
      </PaperTable>
    );
  }

  if (transaction.checkoutDevice?.id) {
    return (
      <PaperTable headline={<Translate id="orders.device" />}>
        {transaction.checkoutDevice && transaction.checkoutDevice.type === 'sco' && (
          <PaperTableRow label={<Translate id="orders.type" />} value={<Translate id="orders.typeSco" />} />
        )}
        {transaction.checkoutDevice && transaction.checkoutDevice.type === 'pos' && (
          <PaperTableRow label={<Translate id="orders.type" />} value={<Translate id="orders.typePos" />} />
        )}
        <PaperTableRow label={<Translate id="orders.scoExternal" />} value={!!transaction.checkoutDevice && transaction.checkoutDevice.externalID} />
        <PaperTableRow label={<Translate id="orders.scoID" />} value={transaction.checkoutDevice.id} />
        <PaperTableRow label={<Translate id="orders.sco" />} value={!!transaction.checkoutDevice && transaction.checkoutDevice.name} />
        {transaction.cashier?.subject && <PaperTableRow label={<Translate id="orders.cashier" />} value={transaction.cashier?.subject} />}
      </PaperTable>
    );
  }

  return (
    <PaperTable headline={<Translate id="orders.device" />}>
      <PaperTableRow label={<Translate id="orders.type" />} value={<Translate id="orders.typeApp" />} />
      <PaperTableRow label={<Translate id="orders.appUserID" />} value={transaction.appUser?.id} />
      <PaperTableRow label={<Translate id="orders.clientID" />} value={transaction.appUser?.clientID} />
      <PaperTableRow label={<Translate id="orders.sessionID" />} value={transaction.session} />
    </PaperTable>
  );
}

export default OrderViewDevice;
