import { connect } from 'react-redux';
import StatisticApprovalsView from '../components/StatisticApprovalsView';
import { fetchApprovalDurationStatistic, resetApprovalDurationStatistic } from '../actions';

const StatisticApprovalsContainer = connect(
  ({
    approvalDurationStatistic,
    fetchingApprovalDurationStatistic,
    range,
    shopFilter,
    shops,
  }) => ({
    showLoadingIndicator: fetchingApprovalDurationStatistic,
    approvalDurationStatistic,
    defaultRange: range,
    defaultShop: shopFilter,
    shops,
  }),
  dispatch => ({
    fetchApprovalDurationStatistic: (from, to, shopID) =>
      dispatch(fetchApprovalDurationStatistic(from, to, shopID)),
    resetApprovalDurationStatistic: () =>
      dispatch(resetApprovalDurationStatistic()),
  }),
)(StatisticApprovalsView);

export default StatisticApprovalsContainer;
