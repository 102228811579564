import { CheckoutDeviceType } from '../../sco/CheckoutDevice';
import LineItem from './LineItemTypes';
import Payment, { Currency, TaxPortions } from './PaymentTypes';
import { AppUser, Cashier, Customer } from './UserTypes';

export enum TransactionState {
  StateFinal = 'final',
  StateTransferred = 'transferred',
  StateFailed = 'failed',
  StateSuccess = 'success',
  StateUserAborted = 'userAborted',
  StateSystemAborted = 'systemAborted',
  StatePreconditionsNotMet = 'preconditionsNotMet',
  StatePaymentFailed = 'paymentFailed',
}

export interface Device {
  id: string;
  type: CheckoutDeviceType;
  name: string;
  externalID?: string;
}

interface Shop {
  id: string;
  name: string;
  externalID: string;
  phone?: string;
  email?: string;
  street?: string;
  zip?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  country?: string;
}

interface FulfillmentError {
  type: string;
  refersTo: string;
  message: string;
}

export interface Fulfillment {
  id: string;
  type: string;
  state: string;
  refersTo?: string[];
  link?: string;
  errors?: FulfillmentError[];
}

interface SecuritydeviceModule {
  serialNumber: string;
  signatureAlgorithm: string;
  logTimeFormat: string;
  certificate: string;
  publicKey: string;
}

interface SecuritydeviceLog {
  timestamp: string;
  signatureCounter: number;
  signature: string;
  processType?: string;
  processData?: string;
  error?: string;
  errorDetail?: string;
}

interface FiscalReference {
  transactionNumber?: number;
  securityDeviceType: string;
  qrCodeContent?: string;
  transactionLink?: string;
  securityDeviceModule?: SecuritydeviceModule;
  securityDeviceLogs?: SecuritydeviceLog[];
}

interface Links {
  self: string;
  receipt: string;
}

enum CheckType {
  TypeSupervisorApproval = 'supervisor_approval',
  TypeMinAge = 'min_age',
  TypeVerifyDebitCard = 'verify_debit_card',
}

enum CheckState {
  StateFailed = 'failed',
  StatePending = 'pending',
  StatePostponed = 'postponed',
  StateSuccessful = 'successful',
}

interface Check {
  id: string;
  type: CheckType;
  state: CheckState;
  completedAt?: string;
}

export enum TransactionKind {
  KindSale = 'sale',
  KindClosing = 'closing',
}

export interface Transaction {
  id: string;
  project: string;
  session: string;
  state: TransactionState;
  startedAt: string;
  finalizedAt: string;
  date: string;
  createdAt: string;
  appUser?: AppUser;
  checkoutDevice?: Device;
  shop: Shop;
  customer?: Customer;
  payments?: Payment[];
  lineItems?: LineItem[];
  fulfillments?: Fulfillment[];
  currency?: Currency;
  price?: number;
  netPrice?: number;
  subTotal?: number;
  tax?: TaxPortions;
  taxNet?: TaxPortions;
  taxPre?: TaxPortions;
  fiscalReference?: FiscalReference;
  links?: Links;
  cashRegisterID?: string;
  cashRegisterSerialNumber?: string;
  sequenceNumber: number;
  receiptNumber?: number;
  externalCheckoutID?: string;
  externalCheckoutReference?: string;
  cashier?: Cashier;
  checks?: Check[];
  kind?: TransactionKind;
}

interface TransactionPagination {
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
}

export interface TransactionList {
  resources: Transaction[];
  pagination: TransactionPagination;
}
