import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import useCategoriesApi, { useCategoryProductApi, useLegacyCategoriesApi } from './useCategoriesApi';
import CategoriesForm from './CategoriesForm';
import ResourceFormView from '../scaffold/ResourceFormView';
import CategoriesFormLegacy from './CategoriesFormLegacy';
import { projectUsesOldCategoriesEditor } from '../utils/features';
import DocumentTitle from '../components/DocumentTitle';

export default function UpdateCategoriesView() {
  const api = useCategoriesApi();
  const legacyApi = useLegacyCategoriesApi();
  const productsApi = useCategoryProductApi();
  const { projectId } = useParams();

  const handleProductLookup = useCallback(async (sku: string) => {
    const products = await productsApi.getProduct({ sku });
    return products;
  }, [productsApi]);

  if (projectId && projectUsesOldCategoriesEditor(projectId)) {
    return (
      <ResourceFormView
        actionName="updateCategories"
        Form={CategoriesFormLegacy}
        fetch={() => legacyApi.get({})}
        submit={data => legacyApi.update({ data })}
      />
    );
  }

  return (
    <>
      <DocumentTitle translationID="productMenus.headlines.editCategories" />
      <ResourceFormView
        actionName="updateCategories"
        Form={CategoriesForm}
        FormProps={{
          onProductLookup: handleProductLookup,
        }}
        fetch={() => api.get({})}
        submit={data => api.update({ data })}
      />
    </>
  );
}
