import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../form';
import { ProjectConfig } from './useProjectConfigApi';
import { ImageField, TextField } from '../form/input';
import { ShopService } from './shopServices';

const DEFAULT_VALUES: ShopService | {} = {};

export interface EditShopServiceDialogFormProps extends CustomFormProps<ProjectConfig | {}> {
  onCancel: () => void;
}

export default function EditShopServiceDialogForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  onCancel,
}: EditShopServiceDialogFormProps) {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState,
    watch,
  } = useEnhancedForm({ defaultValues, errors });

  const deTranslation = watch('translations.de');
  const enTranslation = watch('translations.en');
  const validateSomeTranslationSet = () => !!(deTranslation || enTranslation) || t('form.errors.noTranslation');

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="translations.de"
        label={t('shopServices.deTranslation')}
        rules={{ validate: { validateSomeTranslationSet } }}
      />
      <TextField
        control={control}
        name="translations.en"
        label={t('shopServices.enTranslation')}
        rules={{ validate: { validateSomeTranslationSet } }}
      />
      <ImageField
        control={control}
        name="iconURL"
        label={t('shopServices.iconURL')}
      />

      <DefaultFormActions
        formState={formState}
        onCancel={onCancel}
      />
    </Form>
  );
}
