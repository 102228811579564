import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import WarningIcon from '@mui/icons-material/WarningAmber';
import SearchIcon from '@mui/icons-material/Search';
import styled from '@mui/system/styled';
import ArrayScaffold, { ArrayScaffoldRef } from '../form/wrapper/ArrayScaffold';
import AsyncContent from '../components/AsyncContent';
import { ProductData } from './useCategoriesApi';

const Card = styled(Box)(() => ({
  aspectRatio: '1/1',
  backgroundColor: '#eef9ff',
  borderRadius: '15px',
  boxShadow: '1.5px 3px 3.5px 1px #dddddd',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  position: 'relative',
  cursor: 'grab',
}));

const CardImage = styled('div')(() => ({
  marginInline: 'auto',
  padding: '8px',
  width: '50%',
  aspectRatio: '1/1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  overflow: 'hidden',
  backgroundColor: 'white',
}));

const CardHeader = styled('div')(() => ({
  fontWeight: 'bold',
  width: 'fit-content',
  marginInline: 'auto',
  textAlign: 'center',
}));

const CardSubHeader = styled('div')(() => ({
  fontStyle: 'italic',
  width: 'fit-content',
  marginInline: 'auto',
  textAlign: 'center',
}));

const CardButtonRight = styled('div')(() => ({
  position: 'absolute',
  right: '0',
  top: '0',
}));

const CardButtonLeft = styled('div')(() => ({
  position: 'absolute',
  left: '0',
  top: '0',
}));

const ResponsiveGrid = (props: any) => (
  <Grid item xs={6} sm={4} md={3} lg={2} {...props} />
);

interface CategoriesFormProductGridProps {
  containerProps: Record<string, any>;
  itemProps: Record<string, any>;
  onAddProduct: () => void;
  onProductLookup?: (search: string) => Promise<ProductData>;
  onProductReplace: (product: string) => void;
  getValues: (path: string) => any;
  control: any;
  name: string;
  arrayScaffoldRef: React.RefObject<ArrayScaffoldRef>;
}

export default function CategoriesFormProductGrid({
  containerProps,
  itemProps,
  onAddProduct,
  onProductLookup,
  onProductReplace,
  control,
  name,
  getValues,
  arrayScaffoldRef,
}: CategoriesFormProductGridProps) {
  const { t } = useTranslation();

  const lookupProduct = useCallback(async (path: string) => {
    const product = getValues(path);
    if (!product || !onProductLookup) return undefined;
    const productInfo = await onProductLookup(product);
    return productInfo;
  }, [getValues, onProductLookup]);

  return (
    <Box sx={{ flexGrow: 1 }} {...containerProps}>
      {/* NOTE MUI call both the grid container and the grid contents <Grid>. The container has the
      *container* argument and the contents have the *item* argument. Confusingly the latter also
      define the breakpoints */}
      <Grid container spacing={0} gap={2}>
        <ResponsiveGrid>
          <Button
            onClick={() => onAddProduct()}
            component="div"
            sx={{
              aspectRatio: '1/1',
              backgroundColor: '#bbe6ff',
              borderRadius: '15px',
              boxShadow: '1.5px 3px 3.5px 1px #dddddd',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <CardImage>
              <AddIcon fontSize="large" color="primary" />
            </CardImage>
            <CardHeader>
              {t('categories.arrayLabel.addItem')}
            </CardHeader>
          </Button>
        </ResponsiveGrid>
        <ArrayScaffold
          control={control}
          name={name}
          defaultValue={{}}
          rules={{
            validate: (value: any) => {
              const duplicateIndex: number[] = [];
              value.forEach((item: any, index: number) => {
                // since indexOf stops at the first match the filter preserves the first instance
                if (value.indexOf(item) !== index) duplicateIndex.push(index);
              });
              return !duplicateIndex.length || t('categories.arrayLabel.duplicateItem');
            },
          }}
          render={(itemIndex: number, _, remove: () => void) => (
            <AsyncContent
              fetch={() => lookupProduct(`${name}.${itemIndex}`)}
              refetch
              render={content => (
                <ResponsiveGrid
                  key={getValues(`${name}.${itemIndex}.sku`)}
                  {...itemProps}
                  data-index={itemIndex}
                >
                  <Card>
                    {content && (
                      <CardImage>
                        {content.imageUrl ? (
                          <img
                            src={content.imageUrl}
                            alt={content.name}
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                            loading="lazy"
                            draggable={false}
                          />
                        ) : (
                          <ImageNotSupportedIcon fontSize="large" color="disabled" />
                        )}
                      </CardImage>
                    )}
                    <CardHeader>{content?.name || ''}</CardHeader>
                    <CardSubHeader>{content?.sku || ''}</CardSubHeader >
                    <CardButtonRight>
                      <IconButton onClick={() => remove()}><DeleteIcon /></IconButton>
                    </CardButtonRight>
                    <CardButtonLeft>
                      <IconButton onClick={() => onProductReplace(`${name}.${itemIndex}`)}>
                        <EditIcon />
                      </IconButton>
                    </CardButtonLeft>
                  </Card>
                </ResponsiveGrid>
              )}
              renderLoading={() => (
                <ResponsiveGrid>
                  <Card>
                    <CardImage>
                      <Skeleton variant="rectangular" sx={{ maxHeight: '100%', maxWidth: '100%' }} />
                    </CardImage>
                    <CardHeader>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '80%' }} />
                    </CardHeader>
                    <CardSubHeader>
                      <Skeleton variant="text" sx={{ fontSize: '1rem', width: '80%' }} />
                    </CardSubHeader>
                  </Card>
                </ResponsiveGrid>
              )}
              renderError={() => (
                <ResponsiveGrid
                  key={getValues(`${name}.${itemIndex}`)}
                  {...itemProps}
                  data-index={itemIndex}
                >
                  <Card>
                    <CardImage>
                      <IconButton onClick={() => onProductReplace(`${name}.${itemIndex}`)}>
                        <SearchIcon fontSize="large" color="primary" />
                      </IconButton>
                    </CardImage>
                    <CardHeader>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <WarningIcon color="warning" fontSize="small" />
                        <span style={{ fontStyle: 'italic', fontSize: 'smaller' }}>
                          {t('categories.arrayLabel.skuNotFound')}
                        </span>
                      </Stack>
                    </CardHeader>
                    <CardSubHeader>
                      {getValues(`${name}.${itemIndex}`)}
                    </CardSubHeader>
                    <CardButtonRight>
                      <IconButton onClick={() => remove()}>
                        <DeleteIcon />
                      </IconButton>
                    </CardButtonRight>
                  </Card>
                </ResponsiveGrid>
              )}
            />
          )}
          ref={arrayScaffoldRef}
        />
      </Grid>
    </Box>
  );
}
