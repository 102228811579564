import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { OrderViewContent } from './OrderView';
import ResourceChartView from '../charts/scaffold/ResourceChartView';
import { ApplicationState } from '../reducers/initialState';
import useOrderApi from './useOrderApi';
import { MappedSupervisingResult } from './supervisingTypes';
import HeadlineWithButtons from '../components/HeadlineWithButtons';
import Translate from '../components/i18n/Translate';
import ContentLink from '../components/ProjectContentLink';

export interface OrderQuickEditDrawerProps {
  selectedOrderId: string | null;
  onClose: () => void;
}

export default function OrderQuickEditDrawer({
  selectedOrderId,
  onClose,
}: OrderQuickEditDrawerProps) {
  const projectCurrency = useSelector((state: ApplicationState) => state.projectCurrency);
  const orderApi = useOrderApi();

  const [supervising, setSupervising] = useState<MappedSupervisingResult | null>(null);
  const [receipt, setReceipt] = useState<any>(null);

  const fetch = useCallback(async () => {
    if (!selectedOrderId) return {};

    const orderResult = await orderApi.getOrder(selectedOrderId);

    const supervisingResult = await orderApi.getSupervisingResult(orderResult.checkoutProcessID);
    setSupervising(supervisingResult);

    const receiptBlob = await orderApi.getReceipt(orderResult.links?.receipt);
    setReceipt(receiptBlob);

    return orderResult;
  }, [orderApi, selectedOrderId]);

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={!!selectedOrderId}
      onClose={onClose}
      sx={{
        paddingBlock: '0',
        '& .MuiDrawer-paper': {
          width: 'min(900px, 100vw - 32px)',
          maxWidth: '100dvw',
          paddingInline: '16px',
        },
      }}
    >
      <Box marginBottom="104px">
        <HeadlineWithButtons
          buttons={(
            <ContentLink to={`/orders/${selectedOrderId}`}>
              <Button
                variant="contained"
                endIcon={<VisibilityIcon />}
              >
                <Translate id="orders.detailsLink" />
              </Button>
            </ContentLink>
          )}
        >
          <Translate
            id="orders.drawerHeadline"
            data={{
              orderID: selectedOrderId,
            }}
          />
        </HeadlineWithButtons>
        <ResourceChartView
          Chart={OrderViewContent}
          ChartProps={{
            projectCurrency,
            receipt,
            supervising,
          }}
          fetch={fetch}
        />
      </Box>
    </Drawer>
  );
}
