import { useMemo } from 'react';
import { stringify } from 'qs';
import {
  defineApi,
  useProjectSpecificApiClient,
} from '../api';
import { mapOrder } from './orderHelper';
import mapSupervisingResult from './supervisingHelper';
import { SupervisingResult } from './supervisingTypes';
import { isSuccessfulOrCustomResponse } from '../utils/statusValidator';
import { Filter } from '../charts/chartFilterProps';
import { Transaction, TransactionKind, TransactionList, TransactionState } from './types/TransactionsTypes';
import { prependApiHost } from '../urls';

type UiOrderStateMapping = Record<string, {
  translationID: string;
  apiStates: TransactionState[];
}>;

export const uiOrderStateMapping: UiOrderStateMapping = {
  succeeded: {
    translationID: 'orders.succeededOnly',
    apiStates: [
      TransactionState.StateFinal,
      TransactionState.StateTransferred,
      TransactionState.StateSuccess,
    ],
  },
  succeededNotTransferred: {
    translationID: 'orders.withoutTransferred',
    apiStates: [TransactionState.StateTransferred],
  },
  failed: {
    translationID: 'orders.stateFailed',
    apiStates: [
      TransactionState.StateFailed,
      TransactionState.StateUserAborted,
      TransactionState.StateSystemAborted,
      TransactionState.StatePreconditionsNotMet,
      TransactionState.StatePaymentFailed,
    ],
  },
  all: {
    translationID: 'orders.allStates',
    apiStates: [],
  },
};
const getUiOrderStateMapping = (key: string) => uiOrderStateMapping[key] || uiOrderStateMapping.all;

export type TransactionsFilter = {
  from?: string;
  to?: string;
} & {
  q?: string; // order id search query
  page?: number;
  perPage?: number;
  shopIDs?: string; // can be added multiple times
  state?: TransactionState[]; // can be added multiple times
  includeAllStates?: boolean;
  kind?: TransactionKind;
};

const defaultOrdersFilter: TransactionsFilter = { page: 0, perPage: 50 };

export default function useOrderApi() {
  const useApi = useMemo(() => (defineApi({
    getTransactions: async (client, filter: Filter) => {
      const params: TransactionsFilter = {
        ...defaultOrdersFilter,
        from: filter.range.from,
        to: filter.range.to,
        shopIDs: filter.genericFilter?.shopID,
        q: filter.genericFilter?.orderID,
        state: getUiOrderStateMapping(filter.genericFilter?.orderStatus)?.apiStates,
        page: filter.page - 1,
        kind: TransactionKind.KindSale, // NOTE for now only sale transactions will be shown
      };
      const { data, status } = await client.get<TransactionList>('/retailer/transactions', {
        params,
        paramsSerializer: sParam => stringify(sParam, { arrayFormat: 'repeat' }),
        validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [404]),
      });
      if (status === 404) {
        return {
          resources: [],
          pagination: {
            page: filter.page - 1, perPage: 50, totalItems: 0, totalPages: 0,
          },
        } as TransactionList;
      }
      return data;
    },
    getOrder: async (client, orderId: string): Promise<any> => {
      if (!orderId) return undefined;
      const { data } = await client.get<Transaction>(`/retailer/transactions/${encodeURIComponent(orderId)}`);
      return mapOrder(data);
    },
    getSupervisingResult: async (client, checkoutProcessId: Transaction['id']): Promise<any> => {
      const { data, status } = await client.get<SupervisingResult>(
        `/supervising/results/checkout-id/${checkoutProcessId}`,
        {
          validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [404]),
        },
      );
      if (status === 404) return null;
      return mapSupervisingResult(data);
    },
    getReceipt: async (client, receiptLink: string) => {
      if (!receiptLink) return undefined;
      const { data, status } = await client.get(prependApiHost(receiptLink), {
        responseType: 'blob',
        headers: {
          accept: 'application/pdf',
        },
        validateStatus: statusCode => isSuccessfulOrCustomResponse(statusCode, [404]),
      });
      if (status === 404) return null;
      return data;
    },
  })), []);

  const client = useProjectSpecificApiClient({ basePath: '/' });
  return useApi(client);
}
