import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import Translate from './i18n/Translate';
import HorizontalDiagramBar from './HorizontalDiagramBar';
import ValueWithPercentage from './ValueWithPercentage';
import shopLabel, { buildShopLabel } from '../shop';

const styles = theme => ({
  table: {
    marginBottom: theme.spacing(4),
  },
  toggleDetailsFormGroup: {
    display: 'inline-flex',
    marginBottom: theme.spacing(2),
  },
  shopCell: {
    width: '25%',
  },
  cell: {
    width: '10%',
  },
  graphCell: {
    width: '15%',
    minWidth: '6rem',
  },
  percentage: {
    color: theme.palette.grey[500],
  },
});

const COLUMN_WIDTHS = {
  num: {
    compact: 150,
    detailed: 70,
  },
  graph: {
    compact: 180,
    detailed: 120,
  },
};

function StatisticApprovalsTable({
  classes,
  statistic,
  loading = false,
  detailed = false,
  shops = [],
}) {
  const items = statistic?.items || [];

  const [detailedView, setDetailedView] = useState(detailed);

  let numColumnWidth = COLUMN_WIDTHS.num.compact;
  let graphColumnWidth = COLUMN_WIDTHS.graph.compact;
  if (detailedView) {
    numColumnWidth = COLUMN_WIDTHS.num.detailed;
    graphColumnWidth = COLUMN_WIDTHS.graph.detailed;
  }

  const maxDurationMedian = Math.max(...items.map(item => item.durationMedian));
  const maxDurationPercentile90 = Math.max(...items.map(item => item.durationPercentile90));

  const { t } = useTranslation();

  const columns = [
    {
      field: 'shop',
      headerName: t('approvalDurationStatistic.shop'),
      minWidth: 250,
      flex: 1,
      valueGetter: params => shopLabel(params?.value),
      renderCell: (params) => {
        if (params.id === '_summary') {
          return (
            <em>
              <Translate id="approvalDurationStatistic.summary" />
            </em>
          );
        }

        if (!params.id) {
          return t('approvalDurationStatistic.unknownShop');
        }

        return buildShopLabel(shops.find(shop => shop.id === params.id));
      },
    },
    {
      field: 'totalCheckouts',
      headerName: t('approvalDurationStatistic.totalCheckouts'),
      minWidth: numColumnWidth,
    },
    {
      field: 'intervenedCheckouts',
      headerName: t('approvalDurationStatistic.intervenedCheckouts'),
      minWidth: numColumnWidth,
      renderCell: params => (
        <ValueWithPercentage
          value={params.value}
          percentage={params.row.intervenedCheckoutsPercentage}
        />
      ),
    },
  ];

  if (detailedView) {
    columns.push(
      {
        field: 'supervisorChecks',
        headerName: t('approvalDurationStatistic.supervisorChecks'),
        minWidth: numColumnWidth,
        renderCell: params => (
          <ValueWithPercentage
            value={params.value}
            percentage={params.row.supervisorChecksPercentage}
          />
        ),
      },
      {
        field: 'ageChecks',
        headerName: t('approvalDurationStatistic.ageChecks'),
        minWidth: numColumnWidth,
        renderCell: params => (
          <ValueWithPercentage
            value={params.value}
            percentage={params.row.ageChecksPercentage}
          />
        ),
      },
      {
        field: 'successfulChecks',
        headerName: t('approvalDurationStatistic.successfulChecks'),
        minWidth: numColumnWidth,
      },
      {
        field: 'failedChecks',
        headerName: t('approvalDurationStatistic.failedChecks'),
        minWidth: numColumnWidth,
      },
      {
        field: 'incompleteChecks',
        headerName: t('approvalDurationStatistic.incompleteChecks'),
        minWidth: numColumnWidth,
      },
    );
  }

  columns.push(
    {
      field: 'durationMedian',
      headerName: t('approvalDurationStatistic.durationMedian'),
      minWidth: graphColumnWidth,
      renderCell: params => (
        <HorizontalDiagramBar
          value={params.value}
          max={maxDurationMedian}
          unit="s"
        />),
    },
    {
      field: 'durationPercentile90',
      headerName: t('approvalDurationStatistic.durationPercentile90'),
      minWidth: graphColumnWidth,
      renderCell: params => (
        <HorizontalDiagramBar
          value={params.value}
          max={maxDurationPercentile90}
          unit="s"
        />),
    },
  );

  const rows = items.map(stat => ({ ...stat, id: stat.shopID }));

  if (statistic?.summary) {
    rows.unshift({ ...statistic.summary, id: '_summary' });
  }

  return (
    <React.Fragment>
      <FormGroup className={classes.toggleDetailsFormGroup}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              value={detailedView}
              onChange={(e) => { setDetailedView(e.target.checked); }}
            />
          }
          label={
            <Typography variant="body2">
              <Translate id="approvalDurationStatistic.toggleDetailedView" />
            </Typography>
          }
        />
      </FormGroup>

      <DataGrid
        className={classes.table}
        columns={columns}
        rows={rows}
        loading={loading}
        hideFooter
        autoHeight
        disableColumnMenu
        disableSelectionOnClick
      />
    </React.Fragment>
  );
}

export default withStyles(styles)(StatisticApprovalsTable);
