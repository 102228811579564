import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
// @ts-ignore
import CheckoutDeviceViewContainer from '../CheckoutDeviceViewContainer';
import RouteWrapper from '../../router/RouteWrapper';
import UpdateGlobalCheckoutDeviceConfigView from '../UpdateGlobalCheckoutDeviceConfigView';
import CreateCheckoutDeviceView from '../CreateCheckoutDeviceView';
import UpdateCheckoutDeviceView from '../UpdateCheckoutDeviceView';
import UpdateCheckoutDeviceConfigView from '../UpdateCheckoutDeviceConfigView';
import { ResourceProvider } from '../../resource';
import { Access } from '../../access';
import CheckoutDeviceListView from '../CheckoutDeviceListView';

export default function CheckoutDevicesRoutes({ access }: { access?: Access }) {
  const { projectId } = useParams();

  return (
    <ResourceProvider name="checkoutDevices">
      <Routes>
        {access?.read && (
          <>
            <Route
              path="/id/:id"
              element={<RouteWrapper><CheckoutDeviceViewContainer /></RouteWrapper>}
            />
            <Route
              path="/"
              element={<RouteWrapper><CheckoutDeviceListView /></RouteWrapper>}
            />
          </>
        )}

        {access?.write && (
          <>
            <Route
              path="/new"
              element={<CreateCheckoutDeviceView />}
            />
            <Route
              path="/config"
              element={<UpdateGlobalCheckoutDeviceConfigView />}
            />
            <Route
              path="/:id/config"
              element={<UpdateCheckoutDeviceConfigView />}
            />
            <Route
              path="/:id/edit"
              element={<UpdateCheckoutDeviceView />}
            />
          </>
        )}

        <Route path="/*" element={<Navigate to={`/${projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}
