import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import useResourceTranslator from '../resource/useResourceTranslator';
import { ProjectConfig, RoundingMode } from './useProjectConfigApi';
import { NumberField, SelectOptionsField, TextField } from '../form/input';
import CheckboxField from '../form/input/CheckboxField';
import CURRENCIES, { Currency } from './Currencies';

const DEFAULT_VALUES: ProjectConfig | {} = {
  companyAddress: {},
};

export interface EditProjectConfigFormProps extends CustomFormProps<ProjectConfig | {}> {
}

export default function EditProjectConfigForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: EditProjectConfigFormProps) {
  const t = useResourceTranslator();
  const [, i18n] = useTranslation();
  const language = useMemo(() => i18n.resolvedLanguage || 'de', [i18n.resolvedLanguage]);

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset category="general" legend={t('projectConfig.general')}>
        <TextField
          control={control}
          name="name"
          label={t('projectConfig.name')}
        />
        <TextField
          control={control}
          name="displayName"
          label={t('projectConfig.displayName')}
        />
      </Fieldset>

      <Fieldset category="company" legend={t('projectConfig.company')}>
        <TextField
          control={control}
          name="companyName"
          label={t('projectConfig.companyName')}
        />
        <TextField
          control={control}
          name="companyAddress.street"
          label={t('projectConfig.companyAddress.street')}
        />
        <TextField
          control={control}
          name="companyAddress.zip"
          label={t('projectConfig.companyAddress.zip')}
        />
        <TextField
          control={control}
          name="companyAddress.city"
          label={t('projectConfig.companyAddress.city')}
        />
        <TextField
          control={control}
          name="companyAddress.country"
          label={t('projectConfig.companyAddress.country')}
        />
        <TextField
          control={control}
          name="companyAddress.phone"
          label={t('projectConfig.companyAddress.phone')}
        />
        <TextField
          control={control}
          name="companyAddress.email"
          label={t('projectConfig.companyAddress.email')}
        />
      </Fieldset>

      <Fieldset category="finance" legend={t('projectConfig.finance')}>
        <TextField
          control={control}
          name="taxNumber"
          label={t('projectConfig.taxNumber')}
        />
        <TextField
          control={control}
          name="taxID"
          label={t('projectConfig.taxID')}
        />
        <SelectOptionsField
          control={control}
          name="currency"
          options={Object.keys(CURRENCIES)}
          labelFrom={(currency: string) => `${(CURRENCIES[currency as Currency])?.[language === 'en' ? 'englishName' : 'germanName'] || ''} (${currency})`}
          label={t('projectConfig.currency')}
        />
        <NumberField
          control={control}
          name="decimalDigits"
          label={t('projectConfig.decimalDigits')}
        />
        <SelectOptionsField
          control={control}
          name="roundingMode"
          label={t('projectConfig.roundingMode.label')}
          options={Object.values(RoundingMode)}
          labelFrom={(mode: RoundingMode) => t(`projectConfig.roundingMode.${mode}`)}
        />
        <CheckboxField
          control={control}
          name="displayNetPrice"
          label={t('projectConfig.displayNetPrice')}
        />
      </Fieldset>

      <Fieldset category="texts" legend={t('projectConfig.texts.fieldset')}>
        <TextField
          control={control}
          name="texts.sepaMandateShort"
          label={t('projectConfig.texts.sepaMandateShort')}
          multiline
        />
        <TextField
          control={control}
          name="texts.sepaMandate"
          label={t('projectConfig.texts.sepaMandate')}
          multiline
          rows={10}
        />
        <TextField
          control={control}
          name="texts.receiptContentPreFooter"
          label={t('projectConfig.texts.receiptContentPreFooter')}
          helperText={t('projectConfig.helperText.receiptContentPreFooter')}
          multiline
          rows={10}
        />
      </Fieldset>

      <DefaultFormActions
        formState={formState}
      />
    </Form>
  );
}
