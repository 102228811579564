import React from 'react';
import { Route, Routes, Navigate, useParams } from 'react-router-dom';
import { Access } from '../access';
import { ResourceProvider } from '../resource';
import UpdateCategoriesView from '../productsMenu/UpdateCategoriesView';
import UpdateSingleItemsView from '../productsMenu/UpdateSingleItemsView';

function ProductMenuRoutes({ access }: { access?: Access }) {
  return (
    <ResourceProvider name="products">
      <Routes>
        {access?.write && (
          <>
            <Route
              path="/edit-categories"
              element={<UpdateCategoriesView />}
            />
            <Route
              path="/edit-single-items"
              element={<UpdateSingleItemsView />}
            />
          </>
        )}
        <Route path="/*" element={<Navigate to={`/${useParams().projectId}/404`} replace />} />
      </Routes>
    </ResourceProvider>
  );
}

export default ProductMenuRoutes;
