import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import VisibleIcon from '@mui/icons-material/Done';
import NotVisibleIcon from '@mui/icons-material/Block';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import {
  CustomFormProps,
  Form,
  useEnhancedForm,
} from '../form';
import { formatCell } from '../SortableTable';
import useProjectNavigate from '../useProjectNavigate';
import { ReactComponent as ShopAccessIcon } from '../icons/passkey.svg';
import TranslatedTooltip from '../components/i18n/TranslatedTooltip';

const DEFAULT_VALUES = {};

export default function EditAppUserForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: CustomFormProps) {
  const { t } = useTranslation();
  const navigate = useProjectNavigate();

  const data = defaultValues;
  const {
    handleSubmit,
  } = useEnhancedForm({ defaultValues, errors });

  const handleViewUserInShopAccessJournal = useCallback(() => {
    if (!data?.phoneNumber) return;
    navigate('/shop-access/', {
      state: {
        presetIdFilter: data.phoneNumber,
      },
    });
  }, [data.phoneNumber, navigate]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PaperTable headline={t('appUser.headlines.userDetails')}>
          <PaperTableRow
            label={t('appUser.firstName')}
            value={data?.details?.firstName || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.lastName')}
            value={data?.details?.lastName || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.dateOfBirth')}
            value={data?.details?.dateOfBirth || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.email')}
            value={data?.details?.email || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.street')}
            value={data?.details?.street || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.zip')}
            value={data?.details?.zip || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.city')}
            value={data?.details?.city || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.state')}
            value={data?.details?.state || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.country')}
            value={data?.details?.country || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.acceptedPrivacyTermsAt')}
            value={data?.details?.acceptedPrivacyTermsAt || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.acceptedTermsOfUseAt')}
            value={data?.details?.acceptedTermsOfUseAt || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.emailIsVerified')}
            value={data.emailIsVerified ? <VisibleIcon /> : <NotVisibleIcon />}
          />
          <PaperTableRow
            label={t('appUser.detailsCreatedAt')}
            value={data?.details?.createdAt ? formatCell(data?.details?.createdAt, 'datetime') : '-/-'}
          />
          <PaperTableRow
            label={t('appUser.detailsUpdatedAt')}
            value={data?.details?.updatedAt ? formatCell(data?.details?.updatedAt, 'datetime') : '-/-'}
          />
        </PaperTable>

        <PaperTable headline={t('appUser.headlines.user')}>
          <PaperTableRow
            label={t('appUser.id')}
            value={data?.id}
          />
          <PaperTableRow
            label={t('appUser.phoneNumber')}
            value={(
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <span>{data?.phoneNumber || '-/-'}</span>
                {data?.phoneNumber && (
                  <TranslatedTooltip title="viewUserInShopAccessJournal">
                    <IconButton size="small" onClick={handleViewUserInShopAccessJournal} sx={{ padding: 0 }}>
                      <ShopAccessIcon />
                    </IconButton>
                  </TranslatedTooltip>
                )}
              </Stack>
            )}
          />
          <PaperTableRow
            label={t('appUser.userCreatedAt')}
            value={formatCell(data?.createdAt, 'datetime')}
          />
        </PaperTable>
      </Form>
    </>
  );
}
