import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import toPairs from 'lodash/toPairs';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import { flattenObject } from '../utils/objectUtils';
import DownloadAsLink from '../components/DownloadAsLink';
import { Transaction } from './types/TransactionsTypes';
import TransactionStateIndicator from '../orders/TransactionStateIndicator';
// @ts-ignore
import PaymentMethodIcon from '../components/PaymentMethodIcon';
// @ts-ignore
import { formatPrice } from '../components/ProductFormatPrice';

interface OrderPaymentResultProps {
  transaction: Transaction;
}

export default function OrderPaymentResult({
  transaction,
}: OrderPaymentResultProps) {
  const { t } = useTranslation();
  const [expandedPayment, setExpandedPayment] = useState<number | null>(null);

  const renderTableRowList = useCallback((translatedKey: string, value: any[]) => (
    <PaperTableRow
      label={translatedKey}
      value={(
        <ul>
          {value.map(item => (
            <li>{JSON.stringify(item, null, 2)}</li>
          ))}
        </ul>
      )}
    />
  ), []);

  const renderDownloadLink = useCallback((name: string, receipt: string) => (
    (name && receipt) && <DownloadAsLink
      blob={new Blob([receipt], { type: 'text/plain;charset=utf-8' })}
      name={name}
      type="txt"
    />
  ), []);

  const renderTableRow = useCallback(([key, value]: [string, any]) => {
    // NOTE payment results change frequently. So a good fallback is needed for the translation.
    // For this the key itself is used but the dots are replaced with arrows.
    const tranlatedKey = t(`paymentResult.${key}`, {
      defaultValue: key.replace(/\./g, ' → '),
    });

    if (Array.isArray(value)) return renderTableRowList(tranlatedKey, value);
    if (key === 'customerReceipt') {
      return (
        <PaperTableRow
          label={tranlatedKey}
          value={renderDownloadLink(`customer-receipt-${transaction?.receiptNumber}`, value)}
        />
      );
    }
    if (key === 'merchantReceipt') {
      return (
        <PaperTableRow
          label={tranlatedKey}
          value={renderDownloadLink(`merchant-receipt-${transaction?.receiptNumber}`, value)}
        />
      );
    }

    const formattedValue = String(value || '-/-');
    return <PaperTableRow label={tranlatedKey} value={formattedValue} />;
  }, [renderDownloadLink, renderTableRowList, t, transaction?.receiptNumber]);

  if (!transaction?.payments?.length) return null;

  return (
    <>
      <PaperTable headline={t('paymentResult.headline')}>
        {transaction.payments?.map((payment, index) => (
          <PaperTableRow
            label={(
              <PaymentMethodIcon
                paymentMethod={payment.method}
                project={transaction.project}
                paymentCardType={payment.cardType}
              />
            )}
            value={(
              <>
                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                >
                  <Stack
                    alignItems="center"
                    justifyContent="start"
                    gap={1}
                    direction="row"
                  >
                    {formatPrice(payment.paidAmount, payment.currency)}
                    <TransactionStateIndicator
                      state={transaction.state}
                      paymentState={payment.state}
                      paymentResult={payment.result}
                    />
                  </Stack>
                  <Button
                    size="small"
                    endIcon={(
                      <ExpandMoreIcon sx={expandedPayment === index ? { transform: 'rotate(180deg)' } : {}} />
                    )}
                    onClick={() => setExpandedPayment(expandedPayment === index ? null : index)}
                  >
                    {expandedPayment === index ? t('orders.hideDetails') : t('orders.showDetails')}
                  </Button>
                </Stack>
                {expandedPayment === index && (
                  <Box>
                    <hr />
                    {toPairs(flattenObject(payment.result)).map(renderTableRow)}
                  </Box>
                )}
              </>
            )}
          />
        ))}
      </PaperTable>
    </>
  );
}
